import { render, staticRenderFns } from "./LoginAndRegister.vue?vue&type=template&id=4a2b3cfc&scoped=true&"
import script from "./LoginAndRegister.vue?vue&type=script&lang=js&"
export * from "./LoginAndRegister.vue?vue&type=script&lang=js&"
import style0 from "./LoginAndRegister.vue?vue&type=style&index=0&id=4a2b3cfc&prod&scoped=true&lang=scss&"
import style1 from "./LoginAndRegister.vue?vue&type=style&index=1&id=4a2b3cfc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a2b3cfc",
  null
  
)

export default component.exports