import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=57eb1b15&scoped=true&"
import script from "./AboutUs.vue?vue&type=script&lang=js&"
export * from "./AboutUs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57eb1b15",
  null
  
)

export default component.exports