<template>
  <div>
    <PagesPath></PagesPath>
    <section
      class="
        vendor-questions
        box-shaddow20

        bg-white
        container
        text-right
        p-3
        my-4
      "
    >
    <b-skeleton v-if="status" type="button"></b-skeleton>
    <br v-if="status">
      <h5 v-else class="text-color-444 weight-bold pt-4">سوالات متداول</h5>
    <b-skeleton v-if="status" ></b-skeleton>

      <p v-else class="fontsize14 text-color-666 line-height2 text-justify my-4">
        سلام به همه دوستدارن خرید اینترنتی و عزیزان فروشگاه  همتا استور,این بخش برای
        شما تدارک دیده شده است تا بتوانید به راحتی با فروشگاه ما کار کنید خرید
        کنید و یا خرید خود را هدیه بدهید و سوالاتی که ممکن است در مورد نحوه خرید
        از فروشگاه و یا نحوه پرداخت داشته باشید را جواب بدهیم تا هیچ ابهامی در
        ذهن شما باقی نماند فقط از شما خواهش میکنیم تا با دقت مطالب آماده شده را
        بخوانید و اگر پاسخ سوال خود را پیدا نکردید با ما تماس بگیرد سپاس گذارم
      </p>
      <div v-if="status">
        <b-skeleton type="input"></b-skeleton>
        <br />
        <b-skeleton type="input"></b-skeleton>
        <br />
        <b-skeleton type="input"></b-skeleton>
        <br />
      </div>

      <div v-else class="accordion" id="accordionExample2">
        <div v-for="(item,index) in faqList" :key="item.id" class="card mb-2" :id="'faq'+item.id" :class="{'card-border':cardBorder==index}" >
          <div class="card-header text-right" id="headingOne">
            <h6 class="mb-0">
              <!-- با کلیک بروی هرکدام بوردر به آن بخش اضافه شود -->
              <button
                @click="cardBorder=index"
                class="
                  btn btn-link btn-block
                  text-right
                  d-flex
                  justify-content-between
                "
                type="button"
                data-toggle="collapse"
                :data-target="'#collapseOne'+item.id"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                <div class="d-flex align-items-baseline ml-2">
                  <span class="px-2 ml-2 py-1 border-radius7 bg-color-ea">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-question-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
                      />
                    </svg>
                  </span>
                  <p class="mb-0 text-justify">
                  {{item.question}}
                  </p>
                </div>

                <svg
                  class="mt-1"
                  width="17"
                  height="17"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19 8.5L12 15.5L5 8.5"
                    stroke="#666"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </h6>
          </div>

          <div
            :id="'collapseOne'+item.id"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample2"
          >
            <div class="card-body line-height2 text-justify">
             {{item.answer}}
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import {BSkeleton} from 'bootstrap-vue'

export default {
  name: "CommonQuestions",
  data() {
    return {
      cardBorder: 0,
    };
  },
  components: {
    PagesPath,BSkeleton
  },
  created() {
    // در صورت خالی بودن لیست پرسش های متداول از اکشن ویو ایکس آنرا از سرور میخواند
    if (this.faqList == null) {
      this.$store.dispatch("front/getAllFaqListFromServer");
    }
  },
  computed: {
    faqList() {
      return this.$store.getters["front/getFaqList"];
    },
    status() {
      return this.$store.getters["front/getFaqStatus"];
    },
  },
  methods:{

  }
};
</script>

<style scoped>
</style>
